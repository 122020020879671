import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from 'src/app/api-url';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(
    private http: HttpClient
  ) { }

  register(data: any) {
    const formData = new FormData();
    formData.append('username', data.username);
    formData.append('password', data.password);
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    formData.append('birth_date', data.birth_date);
    formData.append('gender', data.gender);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    // formData.append('referral_code', data.referral_code)
    return this.http.post(`${API.register}/register`, formData);
  }
}
