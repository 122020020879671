<body class="position-relative font-inter antialiased">
    <!-- <div class="container-fluid"> -->
    <main class="position-relative min-h-screen d-flex flex-column justify-content-center bg-slate-50 overflow-hidden"
        style="background-color: #f2f7fb;">
        <div class="w-full max-w-6xl mx-auto pe-4 ps-4 md:px-6 py-24" style="margin-top: 50px;">
            <div class="d-flex justify-content-center" style="padding:0px 35%;">
                <div class="container-fluid-lg w-100">
                    <div class="row">
                        <!-- <div class="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                            <div class="image-contain">
                                <img src="assets/images/inner-page/sign-up.png" class="img-fluid" alt="">
                            </div>
                        </div> -->

                        <!-- <div class="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto"> -->
                        <div class="log-in-box">
                            <div class="log-in-title text-center">
                                <h3>Welcome To Alpa Loyalty</h3>
                                <h4>Create New Account</h4>
                            </div>

                            <div *ngIf="alert" id="success-alert" class="alert alert-success" role="alert">
                                Registered Successfull
                            </div>

                            <div class="input-box">
                                <form [formGroup]="formRegister" class="row g-4" [ngClass]="{'mt-2' : !alert}">
                                    <div class="col-12">
                                        <div class="form-floating theme-form-floating">
                                            <input type="text" class="form-control" id="saving_acc_no" formControlName="saving_no"
                                                [(ngModel)]="savingNo">
                                            <label for="fullname">Saving Account Number</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-floating theme-form-floating">
                                            <input formControlName="first_name" type="text" class="form-control"
                                                id="fullname" placeholder="Full Name">
                                            <label for="fullname">First Name</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-floating theme-form-floating">
                                            <input formControlName="last_name" type="text" class="form-control"
                                                id="fullname" placeholder="Full Name">
                                            <label for="fullname">Last Name</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-floating theme-form-floating">
                                            <select formControlName="gender" class="form-select select-form-size"
                                                id="gender">
                                                <!-- <option selected>Select Gender</option> -->
                                                <option value="F">Female</option>
                                                <option value="M">Male</option>
                                            </select>
                                            <!-- <input formControlName="gender" type="text" class="form-control" id="fullname" placeholder="Full Name"> -->
                                            <label for="gender">Gender</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-floating theme-form-floating">
                                            <input formControlName="email" type="email" class="form-control" id="email"
                                                placeholder="Email Address">
                                            <label for="email">Email Address</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-floating theme-form-floating">
                                            <input formControlName="birth_date" type="date" class="form-control"
                                                id="birth_date" placeholder="Birth Date">
                                            <label for="birth_date">Birth Date</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-floating theme-form-floating">
                                            <input formControlName="phone" type="text" class="form-control" id="phone"
                                                placeholder="Phone" maxlength="13">
                                            <label for="phone">Phone</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-floating theme-form-floating">
                                            <input formControlName="password" type="password" class="form-control"
                                                id="password" placeholder="Password">
                                            <label for="password">Password</label>
                                        </div>
                                    </div>
                                    <!-- <div class="col-12">
                                        <div class="form-floating theme-form-floating">
                                            <input (input)="onCheckPassword()" formControlName="conpassword"
                                                type="password" class="form-control" id="password"
                                                placeholder="Password">
                                            <label for="password"> Confirm Password</label>
                                        </div>
                                    </div> -->

                                    <!-- <div class="col-12">
                                            <div class="form-floating theme-form-floating">
                                                <input formControlName="referral_code" type="text" class="form-control"
                                                    id="password" placeholder="Referral Code">
                                                <label for="password"> Refferral Code</label>
                                            </div>
                                        </div> -->


                                    <!-- <div class="col-12">
                                                <div class="custome-radio">
                                                    <input class="form-check-input" required="" type="radio" name="payment_option" id="exampleRadios3" checked="" />
                                                    <label class="form-check-label" for="exampleRadios3" data-bs-toggle="collapse" data-target="#bankTranfer" aria-controls="bankTranfer">I am a customer</label>
                                                </div>
                                                <div class="custome-radio">
                                                    <input class="form-check-input" required="" type="radio" name="payment_option" id="exampleRadios4" checked="" />
                                                    <label class="form-check-label" for="exampleRadios4" data-bs-toggle="collapse" data-target="#checkPayment" aria-controls="checkPayment">I am a vendor</label>
                                                </div>
                                        </div> -->



                                    <!-- <div class="col-12">
                                            <div class="forgot-box">
                                                <div class="form-check ps-0 m-0 remember-box">
                                                    <input (click)="onClickCheckbox()"
                                                        class="checkbox_animated check-box" type="checkbox"
                                                        id="flexCheckDefault">
                                                    <label class="form-check-label" for="flexCheckDefault">I agree with
                                                        <span>Terms</span> and <span>Privacy</span></label>
                                                </div>
                                            </div>
                                        </div> -->

                                    <!-- {{!formRegister.valid}} | {{!clicked}} | {{!isPasswordMatch}} -->

                                    <div class="col-12 mb-5 text-center">
                                        <button *ngIf="!loginRoute" (click)="register()" [disabled]="!formRegister.valid" class="btn"
                                            style="background-color: rgba(25, 78, 128, 1); color: white; font-size: 14px; border-radius: 5px; height: 40px; font-weight: bold;"
                                            type="submit">Sign
                                            Up</button>

                                        <button *ngIf="loginRoute" class="btn" (click)="routeHome()"
                                            style="background-color: rgba(25, 78, 128, 1); color: white; font-size: 14px; border-radius: 5px; height: 40px; font-weight: bold;"
                                            type="submit">Back to Home</button>
                                    </div>
                                    <!-- <div class="col-12 text-center">
                                        <button #loginForm class="btn" data-bs-toggle="modal"
                                            data-bs-target="#loginModal"
                                            style="background-color: rgba(25, 78, 128, 1); color: white; font-size: 14px; border-radius: 5px; height: 40px; font-weight: bold; display: none;"
                                            type="submit">Login</button>
                                    </div> -->
                                </form>
                            </div>

                            <!-- <div class="other-log-in">
                                    <h6>or</h6>
                                </div>
        
                                <div class="log-in-button">
                                    <ul>
                                        <li>
                                            <a href="https://accounts.google.com/signin/v2/identifier?flowName=GlifWebSignIn&amp;flowEntry=ServiceLogin"
                                                class="btn google-button w-100">
                                                <img src="assets/images/inner-page/google.png" class="" alt=""> Sign up with Google
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/" class="btn google-button w-100">
                                                <img src="assets/images/inner-page/facebook.png" class="" alt=""> Sign up with Facebook
                                            </a>
                                        </li>
                                    </ul>
                                </div> -->

                            <!-- <div class="other-log-in">
                                    <h6></h6>
                                </div>

                                <div class="sign-up-box">
                                    <h4>Already have an account?</h4>
                                    <a href="/account/login">Log In</a>
                                </div> -->
                        </div>
                        <!-- </div> -->

                        <!-- <div class="col-xxl-7 col-xl-6 col-lg-6"></div> -->
                    </div>
                </div>
            </div>
        </div>
    </main>
</body>

<div class="modal fade" id="loginModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="background: rgba(255, 255, 255, 0); border: none;">
            <app-login></app-login>
        </div>
    </div>
</div>


<!-- </section> -->
<!-- log in section end -->