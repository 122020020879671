import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { RegisterService } from 'src/app/core/services/pages/register.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterService } from 'src/service/register/register.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @ViewChild('loginForm') loginForm: ElementRef | any;
  public clicked: boolean = false;
  public isPasswordMatch: boolean = false;
  public alert: boolean = false;
  public savingNo: any;
  public loginRoute: any = false;

  formRegister = new FormGroup({
    'saving_no': new FormControl(''),
    'first_name': new FormControl('', [Validators.required]),
    'last_name': new FormControl('', [Validators.required]),
    'email': new FormControl('', [Validators.required]),
    'password': new FormControl(''),
    'conpassword': new FormControl(''),
    'birth_date': new FormControl('', [Validators.required]),
    'gender': new FormControl('', [Validators.required]),
    'phone': new FormControl('', [Validators.required]),
    // 'referral_code': new FormControl(''),
  })

  constructor(
    private registerService: RegisterService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    console.log(this.clicked);
    console.log(this.isPasswordMatch);
  }

  register() {
    sessionStorage.setItem('saving_no', this.savingNo);
    this.registerService.register(this.formRegister.value).subscribe({
      next: (res: any) => {
        this.router.navigate(['/home']);
        this.alert = true;
        setTimeout(() => {
          this.alert = false;
          this.loginRoute = true;
          // this.loginForm.nativeElement.click();
        }, 3000);
        // this.toastr.success('Registered Successfull', 'Success',
        //   { positionClass: 'toast-bottom-right' })
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(`${err.error.message}`, 'Error',
          { positionClass: 'toast-bottom-right' })
      }
    })
  }

  routeHome() {
    window.location.href = '/home';
  }

  showSuccess() {

  }

  onClickCheckbox() {
    this.clicked = !this.clicked;
    console.log(this.clicked);
  }

  onCheckPassword() {
    if (this.formRegister.value.conpassword === this.formRegister.value.password) {
      this.isPasswordMatch = true;
    } else {
      this.isPasswordMatch = false;
    }
  }

}
