import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from 'src/service/auth/auth.service';
import { ProfileService } from 'src/service/profile/profile.service';
import { SimulatorService } from 'src/service/simulator/simulator.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-saving-simulator',
  templateUrl: './saving-simulator.component.html',
  styleUrls: ['./saving-simulator.component.css']
})
export class SavingSimulatorComponent implements OnInit {
  public idLoyalty: any = sessionStorage.getItem('temp_loyaltyId');
  public detailDataCustomer: any = {};
  public listIdentity: any = [];
  public currentProductCode: any;
  public trxId: any;
  public trxOptions: any = [
    { value: 'S', label: 'Saving' },
    { value: 'T', label: 'Transfer' },
    { value: 'Q', label: 'QRIS' },
    { value: 'P', label: 'Payment' }
  ]

  public alert: any;

  public formSaving = new FormGroup({
    gcif: new FormControl(null),
    trxId: new FormControl(null),
    trxDate: new FormControl(null),
    identity: new FormControl(null),
    trxValue: new FormControl(null),
    totalBalance: new FormControl(null),
    productCode: new FormControl(null),
    merchantCode: new FormControl(null),
    merchantName: new FormControl(null)
  })

  constructor(
    protected authService: AuthService,
    private profileService: ProfileService,
    private simulatorService: SimulatorService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    console.log(this.idLoyalty);
    if (this.idLoyalty) {
      this.getDetailCustomer();
      let dateNow = new Date();
      const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
      if (dateNow.getDate() < 10) {
        this.trxId = `${String(String(dateNow.getHours()))}${String(dateNow.getMinutes())}${String(dateNow.getSeconds())}0${String(dateNow.getDate())}${monthNames[dateNow.getMonth()]}${String(dateNow.getFullYear())}`
      }
      else {
        this.trxId = `${String(String(dateNow.getHours()))}${String(dateNow.getMinutes())}${String(dateNow.getSeconds())}${String(dateNow.getDate())}${monthNames[dateNow.getMonth()]}${String(dateNow.getFullYear())}`
      }
      this.formSaving.controls.trxId.setValue(this.trxId);
    }
  }

  getDetailCustomer() {
    this.profileService.getDetailCustomer(this.idLoyalty).subscribe({
      next: (res: any) => {
        this.detailDataCustomer = res.data;
        this.formSaving.controls.gcif.setValue(this.detailDataCustomer.gcif);
        this.detailDataCustomer.customer_account.forEach((e: any) => {
          if (e.channel_code == 'SAVING') {
            this.listIdentity = e.product_data;
          }
        })
      }
    })
  }

  getProductCode(e: any) {
    this.formSaving.controls.identity.setValue(e.target.value);
    this.listIdentity.forEach((id: any) => {
      if (id.identity === e.target.value) {
        this.currentProductCode = id.product_code;
        this.formSaving.controls.productCode.setValue(this.currentProductCode);
      }
    })
  }

  setTrxOption(value: any) {

  }

  submitData() {
    let formData = new FormData();
    this.formSaving.controls.gcif.value !== null ? formData.append('gcif', this.formSaving.controls.gcif.value) : null;
    this.formSaving.controls.trxId.value !== null ? formData.append('trx_id', this.formSaving.controls.trxId.value) : null;
    this.formSaving.controls.identity.value !== null ? formData.append('identity', this.formSaving.controls.identity.value) : null;
    this.formSaving.controls.trxDate.value !== null ? formData.append('transaction_datetime', this.formSaving.controls.trxDate.value) : null;
    this.formSaving.controls.trxValue.value !== null ? formData.append('transaction_amount', this.formSaving.controls.trxValue.value) : null;
    this.formSaving.controls.totalBalance.value !== null ? formData.append('total_balance', this.formSaving.controls.totalBalance.value) : null;
    formData.append('product_code', this.currentProductCode);
    this.formSaving.controls.merchantCode.value !== null ? formData.append('merchant_code', this.formSaving.controls.merchantCode.value) : null;
    this.formSaving.controls.merchantName.value !== null ? formData.append('merchant_name', this.formSaving.controls.merchantName.value) : null;

    this.simulatorService.postSavingSimulator(formData).subscribe({
      next: (res: any) => {
        this.alert = true;
        setTimeout(() => {
          this.alert = false;
        }, 3000);
        // this.toastr.success("Submit Saving Simulator Data has been Successfull", "Success", {
        //   positionClass: "toast-bottom-right",
        // });
      },
      error: (e: any) => {
        this.toastr.error(`${e.error.message}`, "Error", {
          positionClass: "toast-bottom-right",
        });
      }
    })
  }
}
