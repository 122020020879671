import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.css']
})
export class OrderSuccessComponent implements OnInit {

  public orderSuccessItems: any[] = [];
  public baseProductImageUrl: string = '';

  constructor(

  ) { }

  ngOnInit(): void {

  }

  backHome() {
    location.href = '/home'
    sessionStorage.removeItem('menu');
  }

  showOrderSuccessItems() {
    // console.log('orderSuccessItems from show', this.orderSuccessItems);
  }
}
