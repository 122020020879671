import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

import { Router } from "@angular/router";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,

    ) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestUrl = request.url;
        let path = requestUrl.split('|');
        /* Set Base Url */
        if (path[0] === 'akses') {
            request = request.clone({ url: environment.config.apiUrl + path[1] });
            let token = localStorage.getItem('token_acc') || '';
            if (token) {
                request = request.clone({
                    headers: request.headers.set('Authorization', 'Bearer ' + token)
                });
            }
            else if (token == null || token == '' || token == undefined) {
                this.router.navigate(['/home']);
            }
        }
        if (path[0] === 'akun') {
            request = request.clone({ url: environment.config.apiUrl + path[1] });
            let token = localStorage.getItem('token_acc') || '';
            if (token) {
                request = request.clone({
                    headers: request.headers.set('Authorization', 'Bearer ' + token)
                });
            }
        }
        if (path[0] === 'voltras') {
            request = request.clone({ url: environment.config.apiUrlVoltras.replace('https://', 'http://') + path[1] });
        }
        if (path[0] === 'publik') {
            request = request.clone({ url: environment.config.apiUrl + path[1] });
        }

        return next.handle(request);
    }
}