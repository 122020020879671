<header id="page-topbar">
    <div class="navbar-header">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box">
                <a href="/home" class="logo logo-dark" (click)="routeToPage('home')">
                    <span class="logo-sm">
                        <img src="assets/images/loyalty-logo-sm.png" alt="" height="22" />
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/loyalty-logo-wht.png" alt="" height="20" />
                    </span>
                </a>

                <a href="/home" class="logo logo-light" (click)="routeToPage('home')">
                    <span class="logo-sm">
                        <img src="assets/images/loyalty-logo-sm.png" alt="" height="22" />
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/loyalty-logo-wht.png" alt="" height="20" />
                    </span>
                </a>
            </div>

            <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
                <i class="fa fa-fw fa-bars"></i>
            </button>
        </div>

        <div class="d-flex">
            <div *ngIf="!auth.isLoggedIn()" class="dropdown d-inline-block">
                <!-- <button type="button" class="btn header-item waves-effect" data-toggle="modal"
                    data-target="#loginModal">
                    <span class="d-none d-xl-inline-block fw-medium font-size-15">
                        Login
                    </span>
                </button> -->
                <button type="button" class="btn header-item font-size-15" data-bs-toggle="modal" data-bs-target="#loginModal">
                    Login
                </button>
            </div>

            <div *ngIf="auth.isLoggedIn()">
                <div *ngFor="let p of earnedPockets | keyvalue" class="dropdown d-inline-block">
                    <a>
                        <button type="button" class="btn header-item waves-effect">
                            <span *ngIf="p.key == 'MILES'" class="d-none d-xl-inline-block fw-medium font-size-15">
                                <i class="fab fa-monero"></i> {{p.value.active}}
                            </span>
                            <span *ngIf="p.key == 'POINT'" class="d-none d-xl-inline-block fw-medium font-size-15">
                                <i class="fab fa-product-hunt"></i> {{p.value.active}}
                            </span>
                            <span *ngIf="p.key == 'CASH'" class="d-none d-xl-inline-block fw-medium font-size-15">
                                <i class="fa fa-money-bill"></i> {{p.value.active}}
                            </span>
                        </button>
                    </a>
                </div>
            </div>

            <!-- <div *ngIf="auth.isLoggedIn()" class="dropdown d-inline-block">
                <a href="activity_poin.php">
                    <button type="button" class="btn header-item waves-effect">
                        <span class="d-none d-xl-inline-block fw-medium font-size-15">
                            <i class="fab fa-product-hunt"></i> 500
                        </span>
                    </button>
                </a>
            </div> -->

            <div *ngIf="auth.isLoggedIn()" class="dropdown d-inline-block">
                <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img *ngIf="detailDataCustomer.gender == 'M'" class="rounded-circle header-profile-user"
                        src="assets/images/users/man.png" alt="Header Avatar">
                    <img *ngIf="detailDataCustomer.gender == 'F'" class="rounded-circle header-profile-user"
                        src="assets/images/users/woman.png" alt="Header Avatar">
                    <span class="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{{nameCust}}</span>
                    <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                    <!-- item-->
                    <a class="dropdown-item" href="/customer/profile" (click)="routeToPage('profile')">
                        <i class="fa fa-user-circle font-size-18 align-middle text-muted me-1"></i> <span
                            class="align-middle"> Profile</span></a>
                    <a class="dropdown-item" href="/customer/activity-transaction/{{state}}" (click)="routeToPage('history')">
                        <i class="fa fa-wallet font-size-18 align-middle me-1 text-muted"></i> <span
                            class="align-middle"> History</span></a>
                    <a class="dropdown-item" (click)="logOut()">
                        <i class="fa fa-sign-out-alt font-size-18 align-middle me-1 text-muted"></i> <span
                            class="align-middle"> Sign out</span></a>
                </div>
            </div>

            <div class="dropdown d-inline-block language-switch">
                <button type="button" class="btn header-item waves-effect" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    ID
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                    <!-- item-->
                    <a href="javascript:void(0);" class="dropdown-item notify-item">
                        <span class="align-middle">Eng</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="topnav">

            <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
                <div class="collapse navbar-collapse" id="topnav-menu-content">
                    <ul class="navbar-nav">
                        <!-- <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-pages" role="button">
                                <i class="uil-apps me-2"></i>Gamification <div class="arrow-down"></div>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="topnav-pages">
                                <a href="calendar.html" class="dropdown-item">Spin Weels</a>
                                <a href="calendar.html" class="dropdown-item">Quiz</a>
                            </div>
                        </li> -->
                        <li class="nav-item" (click)="selectMenu('game')">
                            <a class="nav-link" href="/gamification/menu"
                                [ngStyle]="selectedMenu == 'game' ? {'color' : '#36B1A0', 'font-weight' : '600'} : {'color' : '#7B8190', 'font-weight' : 'normal'}">
                                <i class="fas fa-play me-1"></i> Gamification
                            </a>
                        </li>
                        <li class="nav-item active" (click)="selectMenu('voucher')">
                            <a class="nav-link" href="/catalog/voucher"
                                [ngStyle]="selectedMenu == 'voucher' ? {'color' : '#36B1A0', 'font-weight' : '600'} : {'color' : '#7B8190', 'font-weight' : 'normal'}">
                                <i class="fas fa-ticket-alt me-1"></i> Voucher
                            </a>
                        </li>
                        <li class="nav-item" (click)="selectMenu('miles')">
                            <a class="nav-link" href="/catalog/miles"
                                [ngStyle]="selectedMenu == 'miles' ? {'color' : '#36B1A0', 'font-weight' : '600'} : {'color' : '#7B8190', 'font-weight' : 'normal'}">
                                <i class="fas fa-plane me-1"></i> Miles
                            </a>
                        </li>
                        <li class="nav-item" (click)="selectMenu('goods')">
                            <a class="nav-link" href="/catalog/goods"
                                [ngStyle]="selectedMenu == 'goods' ? {'color' : '#36B1A0', 'font-weight' : '600'} : {'color' : '#7B8190', 'font-weight' : 'normal'}">
                                <i class="fas fa-shopping-basket me-1"></i> Goods
                            </a>
                        </li>
                        <li class="nav-item" (click)="selectMenu('ewallet')">
                            <a class="nav-link" href="/catalog/ewallet"
                                [ngStyle]="selectedMenu == 'ewallet' ? {'color' : '#36B1A0', 'font-weight' : '600'} : {'color' : '#7B8190', 'font-weight' : 'normal'}">
                                <i class="fas fa-wallet me-1"></i> e-Wallet
                            </a>
                        </li>
                        <!-- <li class="nav-item" (click)="selectMenu('')">
                            <a class="nav-link" href="redeem_catalog.php">
                                <i class="fas fa-exchange-alt me-1"></i> Top Up
                            </a>
                        </li> -->
                    </ul>
                </div>
            </nav>

        </div>
    </div>

</header>


<!-- Modal -->
<div class="modal fade" id="loginModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="background: rgba(255, 255, 255, 0); border: none;">
            <app-login></app-login>
        </div>
    </div>
</div>