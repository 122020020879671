import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from 'src/app/api-url';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  constructor(
    private http: HttpClient
  ) { }

  getListProductType() {
    return this.http.get(`${API.public_base}/product-type`);
  }

  getListPocket() {
    return this.http.get(`${API.public_base}/wallet/active`);
  }

  getListCategory() {
    return this.http.get(`${API.public_base}/category`);
  }

  getProductbyProductType(params: HttpParams) {
    return this.http.get(`${API.public_base}/products`, { params });
  }

  getDetailProductById(id: any) {
    let params = new HttpParams();
    params = params.append('id', id)
    return this.http.get(`${API.public_base}/product/${id}`, { params });
  }

}
