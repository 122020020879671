import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from 'src/app/api-url';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpClient
  ) { }

  getBannerBaseImageUrl() {
    return `${environment.config.apiUrl + API.public_base.split('|')[1]}/banner/images`;
  }

  getBannerList() {
    return this.http.get(`${API.public_base}/banner`);
  }

  getProductBaseImageUrl(){
    return `${environment.config.apiUrl + API.public_base.split('|')[1]}/product/images`;
  }

  getPromoEventList(params : HttpParams){
    return this.http.get(`${API.public_base}/promo`, {params : params});
  }
}
