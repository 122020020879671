<div class="container-fluid" style="padding: 10px 30%;">

    <form [formGroup]="formSaving">
        <div class="d-flex flex-column">
            <div class="mb-2 form-floating theme-form-floating log-in-form">
                <input type="text" formControlName="gcif" class="form-control" id="gcif" placeholder="GCIF Number"
                    [readonly]="true">
                <label for="gcif">GCIF</label>
            </div>
            <div class="mt-3 mb-2 form-floating theme-form-floating log-in-form">
                <input type="text" formControlName="trxId" class="form-control" id="trxid" placeholder="">
                <label for="trxId">Transaction Id</label>
            </div>
            <div class="mt-3 mb-2 form-floating theme-form-floating log-in-form">
                <select class="form-select select-form-size" id="identity" (change)="getProductCode($event)">
                    <option value="null" disabled selected>Select the identity</option>
                    <option *ngFor="let id of listIdentity" value="{{id.identity}}">{{id.identity}}
                    </option>
                </select>
                <label for="identity">Account Number</label>
            </div>
            <div class="mt-3 mb-3 form-floating theme-form-floating log-in-form">
                <input type="text" formControlName="productCode" class="form-control" id="productCode" placeholder=""
                    readonly>
                <label for="productCode">Product Code</label>
            </div>
            <div class="mt-3 mb-2 form-floating theme-form-floating log-in-form">
                <select class="form-select select-form-size" id="trxOption">
                    <option value="null" disabled selected>Select Transaction Type</option>
                    <option *ngFor="let opt of trxOptions" (click)="setTrxOption(opt.value)">
                        {{opt.label}}
                    </option>
                </select>
                <label for="trxOption">Transaction Type</label>
            </div>
            <div class="mt-3 mb-2 form-floating theme-form-floating log-in-form">
                <input type="date" formControlName="trxDate" class="form-control" id="trxDate" placeholder="">
                <label for="trxDate">Transaction Date</label>
            </div>
            <div class="mt-3 mb-2 form-floating theme-form-floating log-in-form">
                <input type="text" formControlName="trxValue" class="form-control" id="trxValue" placeholder="">
                <label for="trxValue">Amount</label>
            </div>
            
            <div class="mt-3 mb-2 form-floating theme-form-floating log-in-form">
                <input type="text" formControlName="totalBalance" class="form-control" id="totalBalance" placeholder="">
                <label for="totalBalance">Total Balance</label>
            </div>

            <div class="mt-3 mb-2 form-floating theme-form-floating log-in-form">
                <input type="text" formControlName="merchantCode" class="form-control" id="merchantCode" placeholder="">
                <label for="merchantName">Merchant Code</label>
            </div>
            <div class="mt-3 mb-2 form-floating theme-form-floating log-in-form">
                <input type="text" formControlName="merchantName" class="form-control" id="merchantName" placeholder="">
                <label for="merchantName">Merchant Name</label>
            </div>

        </div>

        <div *ngIf="alert" id="success-alert" class="alert alert-success" role="alert">
            Submit Saving Simulator Data has been Successfull
        </div>

        <div class="mt-3">
            <button id="btnSubmit" class="btn btn-default w-100 justify-content-center" type="submit"
                [disabled]="!authService.isLoggedIn()" (click)="submitData()">
                Submit
            </button>
        </div>
    </form>
</div>