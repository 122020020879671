import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from '../layout/header/header.component';
import { AuthService } from 'src/service/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('closeModal') closeModal: ElementRef | any;
  @Input() numOfSeconds = 120; //seconds

  loginForm: FormGroup;
  otpForm!: FormGroup;
  submitted = false;
  submittedOTP = false;
  error = '';
  returnUrl!: string;
  modal: any;
  loading: boolean | any;
  showLoginForm: boolean = true;
  showOTPForm: boolean = false;

  processLogin: boolean = false;
  processOTP: boolean = false;

  siteKey: string = '6Le3Eb0UAAAAAC5QuUmhjPz-sWVmepOfEQk1FYeq';
  otpstat: any;
  recaptchastatus: any;

  constructor(
    private formBuilder: FormBuilder,
    protected act: HeaderComponent,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,

  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      captcha: ['']
    });
  }

  ngOnInit(): void {
    this.showLoginForm = true;
    this.showOTPForm = false;

    this.otpForm = this.formBuilder.group({
      otp: ['', [Validators.required]],
    });

    this.authService.getConfigList().subscribe({
      next: (configRes: any) => {
        this.otpstat = configRes.data.otpstatus;
        this.recaptchastatus = configRes.data.recaptchastatus;

        // Tambahkan validasi reCAPTCHA jika diperlukan
        if (this.recaptchastatus == 1) {
          this.loginForm.controls['captcha'].setValidators([Validators.required])
        } else {
          this.loginForm.controls['captcha'].clearValidators();
        }
        this.loginForm.controls['captcha'].updateValueAndValidity();
      },
      error: (e: any) => {
        console.error('Failed to retrieve config', e);
      }
    });

    // if (sessionStorage.getItem('state') === 'catalog') {
    //   this.returnUrl = `/catalog/products/list`;
    // } else if (sessionStorage.getItem('state') === 'quiz' || sessionStorage.getItem('state') === 'spinwheel') {
    //   this.returnUrl = `/game/${sessionStorage.getItem('state')}`;
    // } else {
    //   this.returnUrl = `/${sessionStorage.getItem('state')}`;
    // }

    console.log('this_path : ' + location.pathname);
  }

  get f() { return this.loginForm.controls; }
  get g() { return this.otpForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    let formData = new FormData();
    formData.append('email', this.loginForm.controls['username'].value);
    formData.append('password', this.loginForm.controls['password'].value);
    formData.append('g-recaptcha-response', this.loginForm.controls['captcha'].value);
    sessionStorage.setItem('temp_email', this.loginForm.controls['username'].value);

    this.authService.loginCustomer(formData).subscribe({
      next: (res: any) => {
        location.href = location.pathname;
        // this.router.navigate([`${location.pathname}`]);
        this.closeModal.nativeElement.click();
      }
    })

    // if (this.otpstat === 1) {
    //   // Tampilkan form OTP jika otpstat adalah 1
    //   this.processLogin = true;
    //   this.authService.loginCustomer(body).subscribe({
    //     next: (res: any) => {
    //       this.showOTPForm = true;
    //       this.showLoginForm = false;
    //       this.processLogin = false;
    //     },
    //     error: (e: any) => {
    //       this.showLoginForm = true;
    //       this.showOTPForm = false;
    //       this.processLogin = false;
    //     },
    //   });
    // } else {
    //   // Jika OTP tidak diperlukan, langsung masuk
    //   this.processLogin = true;
    //   this.authService.loginCustomer(body).subscribe({
    //     next: (res: any) => {
    //       this.router.navigate([`${this.returnUrl}`]);
    //       this.processLogin = false;
    //     },
    //     error: (e: any) => {
    //       this.showLoginForm = true;
    //       this.processLogin = false;
    //     },
    //   });
    // }
  }

  onOTPSubmit() {
    this.submittedOTP = true;

    if (this.otpForm.invalid) {
      return;
    }
  }
}
