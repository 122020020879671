<body class="position-relative font-inter antialiased">
    <!-- <div class="container-fluid"> -->
    <main class="position-relative min-h-screen d-flex flex-column justify-content-center bg-slate-50 overflow-hidden">
        <div class="w-full max-w-6xl mx-auto pe-4 ps-4 md:px-6 py-24">
            <div class="d-flex justify-content-center">

                <div class="max-w-md mx-auto text-center bg-white px-4 sm py-10 rounded-3 shadow">
                    <header class="mb-8">
                        <h1 class="text-2xl fw-bold mb-1">Input Verification</h1>
                        <p class="text-[15px] text-slate-500">Enter the 6-digit verification code.</p>
                    </header>
                    <form id="otp-form">
                        <div id="inputs" class="d-flex align-items-center justify-content-center gap-3">
                            <input type="text" id="input1" inputmode="numeric"
                                class="w-14 h-14 text-center text-2xl fw-bolder text-slate-900 bg-slate-100 border border-transparent rounded outline-none"
                                (input)="inputCode($event)" (keyup)="deleteCode($event)" maxlength="1" />
                            <input type="text" id="input2" inputmode="numeric"
                                class="w-14 h-14 text-center text-2xl fw-bolder text-slate-900 bg-slate-100 border border-transparent rounded outline-none"
                                (input)="inputCode($event)" (keyup)="deleteCode($event)" maxlength="1" />
                            <input type="text" id="input3" inputmode="numeric"
                                class="w-14 h-14 text-center text-2xl fw-bolder text-slate-900 bg-slate-100 border border-transparent rounded outline-none"
                                (input)="inputCode($event)" (keyup)="deleteCode($event)" maxlength="1" />
                            <input type="text" id="input4" inputmode="numeric"
                                class="w-14 h-14 text-center text-2xl fw-bolder text-slate-900 bg-slate-100 border border-transparent rounded outline-none"
                                (input)="inputCode($event)" (keyup)="deleteCode($event)" maxlength="1" />
                            <input type="text" id="input5" inputmode="numeric"
                                class="w-14 h-14 text-center text-2xl fw-bolder text-slate-900 bg-slate-100 border border-transparent rounded outline-none"
                                (input)="inputCode($event)" (keyup)="deleteCode($event)" maxlength="1" />
                            <input type="text" id="input6" inputmode="numeric"
                                class="w-14 h-14 text-center text-2xl fw-bolder text-slate-900 bg-slate-100 border border-transparent rounded outline-none"
                                (input)="inputCode($event)" (keyup)="deleteCode($event)" maxlength="1" />

                        </div>
                        <div class="mw-100 mx-auto mt-4">
                            <button [disabled]="codeVerif.length !== 6 || processRedeem" (click)="redeemProcess()"
                                class="inline-flex justify-center whitespace-nowrap rounded-3 btn bg-green-600 px-3 py-2 text-white shadow-sm w-100 transition-colors duration-150">{{processRedeem
                                ? 'Processing...' : 'Submit'}}</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </main>
    <!-- </div> -->
</body>