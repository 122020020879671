import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.css']
})
export class SimulatorComponent implements OnInit{
  public breadCrumbItems: Array<{}> | undefined;
  public nameTab : any = sessionStorage.getItem('tabName') || 'saving';

  constructor(){}

  ngOnInit(): void {
    this.getbreadCrumb();
  }

  getbreadCrumb(){
    if(this.nameTab == 'cc'){
      this.breadCrumbItems = [{ label: 'Simulator' }, { label: `Credit Card`, active: true }];
    }
    else {
      this.breadCrumbItems = [{ label: 'Simulator' }, { label: `${this.nameTab}`, active: true }];
    }
    
  }

  getTab(e : any){
    this.nameTab = e;
    sessionStorage.setItem('tabName', this.nameTab);
    this.getbreadCrumb();
  }
}
