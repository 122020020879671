<div class="main-content">

    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 col-xl-4">
                    <div class="card text-white-50" style="background:#31374c">
                        <div class="card-body">
                            <h5 class="mb-2 text-white">{{detailCust.first_name}} {{detailCust.last_name}}</h5>
                            <h5 class="text-white font-size-14">Loyalty ID : {{detailCust.loyalty_id}}</h5>
                            <h5 class="text-white font-size-14">Level : {{detailCust.level}}</h5>
                        </div><!-- end card-body -->
                    </div><!-- end card -->
                    <div class="card text-white-50" style="background:#31374c">
                        <div class="card-header">
                            <h5 class="mb-2 text-white">Pocket Balance</h5>
                        </div>
                        <div *ngFor="let p of earnedPockets | keyvalue">
                            <a style="cursor: pointer;" (click)="getPath(p)">
                                <div class="card-body" style="height: 90px;" [ngClass]="{'bg-warning': state == p.key}">
                                    <div class="float-end">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            style="color: white;" fill="currentColor" class="bi bi-chevron-double-right"
                                            viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                            <path fill-rule="evenodd"
                                                d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                    <div *ngIf="p.key == 'POINT'">
                                        <h5 class="text-white font-size-20">
                                            <i class="fab fa-product-hunt"></i>
                                            {{p.value.active}} {{formatPocket(p.key)}}
                                        </h5>
                                        <span class="text-white ms-4 font-size-12">
                                            {{p.value.nearly_expired[0].value}} {{p.key}} will be expired at
                                            {{p.value.nearly_expired[0].expires_at}}
                                        </span>
                                    </div>

                                    <div *ngIf="p.key == 'CASH'">
                                        <h5 class="text-white font-size-20">
                                            <i class="fa fa-money-bill"></i>
                                            {{p.value.active}} {{formatPocket(p.key)}}
                                        </h5>
                                        <span class="text-white ms-4 font-size-12">
                                            {{p.value.nearly_expired[0].value}} {{p.key}} will be expired at
                                            {{p.value.nearly_expired[0].expires_at}}
                                        </span>
                                    </div>

                                    <div *ngIf="p.key == 'MILES'">
                                        <h5 class="text-white font-size-20">
                                            <i class="fab fa-monero"></i>
                                            {{p.value.active}} {{formatPocket(p.key)}}
                                        </h5>
                                        <span class="text-white ms-4 font-size-12">
                                            {{p.value.nearly_expired[0].value}} {{p.key}} will be expired at
                                            {{p.value.nearly_expired[0].expires_at}}
                                        </span>
                                    </div>

                                    <div *ngIf="p.key != 'MILES' && p.key != 'CASH' && p.key !== 'POINT'">
                                        <h5 class="text-white font-size-20">
                                            <i class="fab fa-get-pocket"></i>
                                            {{p.value.active}} {{formatPocket(p.key)}}
                                        </h5>
                                        <span *ngIf="p.value.nearly_expired" class="text-white ms-4 font-size-12">
                                            {{p.value.nearly_expired[0].value}} {{p.key}} will be expired at
                                            {{p.value.nearly_expired[0].expires_at}}
                                        </span>
                                    </div>

                                </div>
                            </a>
                        </div>
                    </div><!-- end card -->
                </div>

                <div class="col-xl-8">
                    <router-outlet></router-outlet>
                </div>
            </div> <!-- end col-->
        </div> <!-- end row-->

    </div> <!-- container-fluid -->
</div>