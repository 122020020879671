import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/service/profile/profile.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public idLoyalty: any = sessionStorage.getItem('temp_loyaltyId');
  public idPocket: any = sessionStorage.getItem('idPocket');
  public earnedPockets: { [key: string]: { id: string, active: number, nearly_expired: any } } = {};
  public state: any = sessionStorage.getItem('state')?.toUpperCase();
  public detailCust: any = {};
  public listAddress: any;

  constructor(
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    if(!this.state){
      if(!location.pathname.includes('profile')){
        this.state = 'CASH'
      }
    }
    if (this.idLoyalty) {
      this.getDetailProfile();
      this.getPocketDetail();
    }
  }

  getDetailProfile() {
    this.profileService.getDetailCustomer(this.idLoyalty).subscribe({
      next: (res: any) => {
        this.detailCust = res.data;
      }
    })
  }

  getPocketDetail() {
    this.profileService.getDetailPocketActive(this.idLoyalty).subscribe({
      next: (res: any) => {
        this.earnedPockets = res.data;
      }
    })
  }

  getPath(data: any) {
    location.href = `customer/activity-transaction/${data.key.toLowerCase()}`;
    this.state = data.key
    this.idPocket = data.value.id;
    sessionStorage.setItem('idPocket', this.idPocket);
    sessionStorage.setItem('state', this.state);
    sessionStorage.removeItem('tab');
  }

  formatPocket(data: string) {
    return data[0] + data.substring(1).toLowerCase();
  }
}

