import { Injectable, ViewChild } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from 'src/service/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
    @ViewChild('ModalLogin') ModalLogin: any;

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isLoggedIn()) {
            return true;
        }

        this.router.navigate(['/home']);
        sessionStorage.setItem('path', 'home');
        return false;
    }

}
