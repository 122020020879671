<!-- <app-breadcrumb title="Simulator" [breadcrumbItems]="breadCrumbItems" style="text-transform: capitalize;"></app-breadcrumb> -->

<!-- <section class="section-b-space shop-section"> -->
<div class="main-content">

    <div class="page-content">

        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card" style="padding: 30px;">

                        <ul class="nav nav-pills mb-3" id="myTabSimulator" role="tablist"
                            style="justify-content: center;">
                            <li class="nav-item" style="width: 125px; text-align: center;">
                                <a class="nav-link" [ngClass]="{'active' : nameTab == 'saving'}" id="saving-tab"
                                    data-bs-toggle="pill" role="tab" aria-controls="saving" aria-selected="false"
                                    data-bs-target="#saving" (click)="getTab('saving')">Saving</a>
                            </li>
                            <!-- <li class="nav-item" style="width: 125px; text-align: center;">
                                <a class="nav-link" [ngClass]="{'active' : nameTab == 'cc'}" id="cc-tab"
                                    data-bs-toggle="pill" role="tab" aria-controls="cc" aria-selected="false"
                                    data-bs-target="#cc" (click)="getTab('cc')">Credit Card</a>
                            </li> -->
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                            <div *ngIf="nameTab == 'saving'" class="tab-pane fade show active" id="saving"
                                role="tabpanel" aria-labelledby="saving-tab">
                                <app-saving-simulator></app-saving-simulator>
                            </div>
                            <div *ngIf="nameTab == 'cc'" class="tab-pane fade show active" id="cc" role="tabpanel"
                                aria-labelledby="cc-tab">
                                <app-cc-simulator></app-cc-simulator>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- </section> -->