import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OtpComponent } from './layout/otp/otp.component';
import { AuthGuard } from './core/guards/auth.guards';
import { OrderSuccessComponent } from './pages/order-success/order-success.component';
import { RegisterComponent } from './layout/register/register.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  { path: 'otp', component: OtpComponent, canActivate: [AuthGuard] },
  { path: 'register', component: RegisterComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
