import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/service/auth/auth.service';
import { HomeService } from 'src/service/home/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public bannerBaseImageURL: any = this.homeService.getBannerBaseImageUrl();
  public productBaseImageUrl: any = this.homeService.getProductBaseImageUrl();
  public listBanner: any = [];
  public idBanner: any;
  public firstSlider: any = {};
  public currentPromo: any = {};
  public listPromo: any;
  public loading: any = false;

  // params 
  public page: any = 1;
  public perPage: any = 1;
  public totalPage: any = 0;
  // public sort: any = 'end_date';
  // public direction: any = 'esc';

  constructor(
    protected auth: AuthService,
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
    this.getBanner();
    this.getPromoProduct();
  }

  getBanner() {
    this.homeService.getBannerList().subscribe({
      next: (res: any) => {
        this.firstSlider = res.data[0];
        this.listBanner = res.data;
        this.listBanner.shift();
      }
    })
  }

  getPromoProduct() {
    this.loading = true;
    let params = new HttpParams();
    params = params.append('page', this.page);
    params = params.append('perPage', this.perPage);
    // params = params.append('sort', this.sort);
    // params = params.append('direction', this.direction);
    this.homeService.getPromoEventList(params).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.listPromo = res.data;
        this.totalPage = res.total;
        this.currentPromo = res.data[0];

        if (res.total !== 0) {
          // Set the date we're counting down to
          var countDownDate = new Date(res.data[0].end_date).getTime();
          // Update the count down every 1 second
          var x = setInterval(function () {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Output the result in an element with id="demo"
            let data = document.getElementById('demo') as HTMLElement;
            // data.innerHTML = "D-"+days+" 0" + hours + ":" + minutes + ":" + seconds;
            data.innerHTML = `${days}d ${hours}h ${minutes}m ${seconds}s`;

            // If the count down is over, write some text 
            if (distance < 0) {
              clearInterval(x);
              data.innerHTML = "EXPIRED";
            }
          }, 1000);

        }

      }
    })
  }

  pageChangeEvent(e: any) {
    this.page = e;
    this.getPromoProduct();
  }

}
