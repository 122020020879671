<div id="layout-wrapper">
    <div *ngIf="!otp && !regist">
        <app-header></app-header>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
    <div *ngIf="otp || regist">
        <app-otp *ngIf="otp"></app-otp>
        <app-register *ngIf="regist"></app-register>
    </div>
</div>