import { Component } from '@angular/core';
import { RedeemService } from 'src/service/redeem/redeem.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})

export class OtpComponent {
  public codeVerif: any = [];
  public dataRedeem: any = JSON.parse(sessionStorage.getItem('dataRedeem') || '');
  public processRedeem: any;

  constructor(
    private redeemService: RedeemService,
    private router: Router
  ) { }

  inputCode(e: any) {
    if (e.target.value != "") {
      this.codeVerif.push(e.target.value);
      const next = e.target.nextElementSibling;
      if (next) {
        next.focus();
      }
    }
  }

  deleteCode(e: any) {
    if (e.key.toLowerCase() == "backspace" || e.key.toLowerCase() == "delete") {
      this.codeVerif.pop();
      e.target.value = "";
      const prev = e.target.previousElementSibling;
      if (prev) {
        prev.focus();
      }
      return;
    }
  }

  redeemProcess() {
    console.log(this.dataRedeem);
    this.processRedeem = true;
    if (this.dataRedeem) {
      this.redeemService.placeOrder(this.dataRedeem).subscribe({
        next: (res: any) => {
          this.processRedeem = false;
          window.location.href = '/order-success'
          sessionStorage.setItem('orderSuccess', JSON.stringify(res.data));
        }
      })
    }

  }

}
