<div class="main-content">

    <div class="page-content">
        <div class="container-fluid">

            <div class="row" *ngIf="!detailStatus">

                <div class="col-xl-3">
                    <div class="card">
                        <div class="card-header bg-transparent border-bottom">
                            <h5 class="mb-0">Filters</h5>
                        </div>
                        <div class="p-4">
                            <h5 class="font-size-14 mb-3">Pocket</h5>
                            <div class="mt-2">
                                <div *ngFor="let p of allPocket" class="form-check mt-2">
                                    <input type="radio" id="{{p.id}}" name="{{p.name}}" class="form-check-input"
                                        [checked]="pocketSelected == p.name" (change)="changePocket(p.name)">
                                    <label class="form-check-label" for="{{p.name}}">{{p.name}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="p-4">
                            <h5 class="font-size-14">Categories</h5>
                            <div class="mt-2">
                                <div class="form-check mt-2">
                                    <input type="radio" id="all" name="all" class="form-check-input"
                                        (change)="changeCategory(null)" [checked]="idCategory == null">
                                    <label class="form-check-label" for="all">All</label>
                                </div>
                                <div class="form-check mt-2" *ngFor="let c of allCategory">
                                    <input type="radio" id="{{c.name}}" name="{{c.name}}" class="form-check-input"
                                        (change)="changeCategory(c.id)" [checked]="idCategory == c.id">
                                    <label class="form-check-label" for="{{c.name}}">{{c.name}}</label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-xl-9">
                    <div class="card">
                        <div class="card-body">
                            <router-outlet></router-outlet>
                        </div> <!-- end card-body-->
                    </div> <!-- end card-->
                </div> <!-- end col-->
            </div> <!-- end row-->

            <div class="row" *ngIf="detailStatus">
                <router-outlet></router-outlet>
            </div>

        </div> <!-- container-fluid -->
    </div>
    <!-- End Page-content -->
</div>
<!-- end main content-->

<!-- <app-login></app-login> -->