<div class="main-content">

    <div class="page-content">
        <div class="container-fluid">
            <div class="row mt-3">
                <div class="col-12 text-center">
                    <div class="breadscrumb-contain breadscrumb-order">
                        <div class="order-contain justify-content-center">
                            <h3 class="theme-color">Order Success</h3>
                            <h5 class="text-content">Payment Is Successfull</h5>
                        </div>
                        <button (click)="backHome()" class="btn btn-success">
                            <i class="fa fa-arrow-right"></i> Back </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>