import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public title = 'frontend-angular-2';
  public otp: boolean | any;
  public regist: boolean | any;

  ngOnInit(): void {
    this.otp = location.pathname.includes('/otp');
    this.regist = location.pathname.includes('/register');
    console.log(this.regist);
  }

}
