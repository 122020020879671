import { Injectable } from '@angular/core';
import { API } from 'src/app/api-url';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SimulatorService {

  constructor(
    private http: HttpClient
  ) { }

  postSavingSimulator(body: FormData) {
    return this.http.post(`${API.base}/transaction/saving`, body);
  }

  postCCSimulator(body: FormData) {
    return this.http.post(`${API.base}/transaction/creditcard`, body);
  }
}
