import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from 'src/service/auth/auth.service';
// import { ProfileService } from 'src/app/core/services/pages/profile.service';
// import { SimulatorService } from 'src/app/core/services/pages/simulator.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cc-simulator',
  templateUrl: './cc-simulator.component.html',
  styleUrls: ['./cc-simulator.component.css']
})
export class CcSimulatorComponent implements OnInit {
  public idLoyalty: any = sessionStorage.getItem('loyaltyId');
  public detailDataCustomer: any = {};
  public listIdentity: any = [];
  public currentProductCode: any;
  public trxId: any;

  public formCC = new FormGroup({
    gcif: new FormControl(null),
    trxId: new FormControl(null),
    trxDate: new FormControl(null),
    identity: new FormControl(null),
    trxValue: new FormControl(null),
    productCode: new FormControl(null),
    merchantName: new FormControl(null),
    merchantCode: new FormControl(null),
    countryCode: new FormControl(null),
    currencyCode: new FormControl(null)
  })

  constructor(
    protected authService: AuthService,
    // private profileService: ProfileService,
    // private simulatorService: SimulatorService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getDetailCustomer();
    let dateNow = new Date();
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    if (dateNow.getDate() < 10) {
      this.trxId = `${String(String(dateNow.getHours()))}${String(dateNow.getMinutes())}${String(dateNow.getSeconds())}0${String(dateNow.getDate())}${monthNames[dateNow.getMonth()]}${String(dateNow.getFullYear())}`
    }
    else {
      this.trxId = `${String(String(dateNow.getHours()))}${String(dateNow.getMinutes())}${String(dateNow.getSeconds())}${String(dateNow.getDate())}${monthNames[dateNow.getMonth()]}${String(dateNow.getFullYear())}`
    }
    this.formCC.controls.trxId.setValue(this.trxId);
  }

  getDetailCustomer() {
    if (this.idLoyalty) {
      // this.profileService.getDetailProfile(this.idLoyalty).subscribe({
      //   next: (res: any) => {
      //     this.detailDataCustomer = res.data;
      //     this.formCC.controls.gcif.setValue(this.detailDataCustomer.gcif);
      //     this.detailDataCustomer.customer_account.forEach((e: any) => {
      //       if (e.channel_code == 'CREDITCARD') {
      //         this.listIdentity = e.product_data;
      //       }
      //     })
      //   }
      // });
    }

  }

  getProductCode(e: any) {
    this.formCC.controls.identity.setValue(e.target.value);
    this.listIdentity.forEach((id: any) => {
      if (id.identity === e.target.value) {
        this.currentProductCode = id.product_code;
        this.formCC.controls.productCode.setValue(this.currentProductCode);
      }
    })
  }

  submitData() {
    let formData = new FormData();
    this.formCC.controls.gcif.value !== null ? formData.append('gcif', this.formCC.controls.gcif.value) : null;
    this.formCC.controls.trxId.value !== null ? formData.append('trx_id', this.formCC.controls.trxId.value) : null;
    this.formCC.controls.identity.value !== null ? formData.append('identity', this.formCC.controls.identity.value) : null;
    this.formCC.controls.trxDate.value !== null ? formData.append('transaction_datetime', this.formCC.controls.trxDate.value) : null;
    this.formCC.controls.trxValue.value !== null ? formData.append('transaction_amount', this.formCC.controls.trxValue.value) : null;
    formData.append('product_code', this.currentProductCode);
    this.formCC.controls.merchantName.value !== null ? formData.append('merchant_name', this.formCC.controls.merchantName.value) : null;
    this.formCC.controls.merchantCode.value !== null ? formData.append('merchant_code', this.formCC.controls.merchantCode.value) : null;
    this.formCC.controls.countryCode.value !== null ? formData.append('country_code', this.formCC.controls.countryCode.value) : null;
    this.formCC.controls.currencyCode.value !== null ? formData.append('currency_code', this.formCC.controls.currencyCode.value) : null;

    // this.simulatorService.postCCSimulator(formData).subscribe({
    //   next: (res: any) => {
    //     this.toastr.success("Submit CC Simulator Data has been Successfull", "Success", {
    //       positionClass: "toast-bottom-right",
    //     });
    //   },
    //   error: (e: any) => {
    //     this.toastr.error(`${e.error.message}`, "Error", {
    //       positionClass: "toast-bottom-right",
    //     });
    //   }
    // })
  }
}
