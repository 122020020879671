<div class="main-content">

    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
                            <!-- <div class="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0"
                                    class="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                            </div> -->
                            <div class="carousel-inner">
                                <div class="carousel-item active" data-bs-interval="10000">
                                    <a href="{{firstSlider.url}}" class="pe-auto">
                                        <img src="{{bannerBaseImageURL}}/{{firstSlider.images}}" class="d-block w-100"
                                            alt="...">
                                        <div class="carousel-caption d-none d-md-block">
                                            <h5>{{firstSlider.name}}</h5>
                                            <p>{{firstSlider.description}}</p>
                                        </div>
                                    </a>
                                </div>
                                <div *ngFor="let b of listBanner; index as i" id="{{b.id}}" class="carousel-item"
                                    data-bs-interval="10000">
                                    <a href="{{b.url}}" class="pe-auto">
                                        <img src="{{bannerBaseImageURL}}/{{b.images}}" class="d-block w-100" alt="...">
                                        <div class="carousel-caption d-none d-md-block">
                                            <h5>{{b.name}}</h5>
                                            <p>{{b.description}}</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark"
                                data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark"
                                data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div> <!-- end col-->
            </div> <!-- end row-->

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-body"
                            *ngFor="let data of listPromo | paginate: { itemsPerPage: perPage, currentPage: page, totalItems: totalPage }">

                            <div>
                                <div class="row">
                                    <div class="col-md-8">
                                        <div>
                                            <h5><i class="fas fa-fist-raised text-danger"></i>
                                                {{currentPromo.name}} -
                                                <button class="btn btn-danger">
                                                    <span id="demo" class="font-size-18"></span>
                                                </button>
                                            </h5>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="form-inline float-md-end">
                                            <div class="search-box ms-2">
                                                <div class="position-relative">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!loading" class="row">
                                    <div *ngFor="let p of currentPromo.promo_products" class="col-xl-3">
                                        <div class="product-box">
                                            <div class="product-img pt-4 px-4">
                                                <img src="{{p.product_details.images[0]}}" alt=""
                                                    class="img-fluid mx-auto d-block" style="height: 150px;">
                                            </div>
                                            <div class="text-center product-content p-4">

                                                <h5 class="mb-1"><a href="#"
                                                        class="text-dark font-size-16">{{p.product_details.name}}</a>
                                                </h5>
                                                <h5 class="mt-1 mb-0 text-warning">
                                                    <span class="text-muted font-size-12 me-2">
                                                        <del>
                                                            <i class="fab fa-product-hunt font-size-10"></i>
                                                            {{p.product_details.price}}
                                                        </del>
                                                    </span><br />
                                                    <i class="fab fa-product-hunt font-size-14"></i> {{p.special_price}}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div> <!-- end card-body-->
                    </div> <!-- end card-->

                    <div class="mt-3" *ngIf="totalPage != 0">
                        <pagination-controls style="text-align: center;"
                            (pageChange)="pageChangeEvent($event)"></pagination-controls>
                    </div>
                    <!-- <pagination-template style="text-align: center; margin-bottom: 10px;"
                        (pageChange)="pageChangeEvent($event)">
                        <a><i class="fas fa-circle me-1" style="color: #2f374e; font-size: 10px;"></i></a>
                    </pagination-template> -->
                    
                </div> <!-- end col-->
            </div> <!-- end row-->

        </div> <!-- container-fluid -->
    </div>
</div>