import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from 'src/app/api-url';

@Injectable({
  providedIn: 'root'
})
export class RedeemService {

  constructor(
    private http: HttpClient
  ) { }

  placeOrder(orderData: any) {
    return this.http.post(`${API.access_base}/customer/redeem/${sessionStorage.getItem('temp_loyaltyId')}`, orderData);
  }

}
